<template>
	<b-modal id="invalid-image-dialog" ref="invalid-image-dialog" title="Information" ok-title="Okay" ok-only
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<div class="confirmMessage">
			<b-row class="my-3">
				<b-col sm="12">The image is currently uploading please try again later ...
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>
<script>
export default {
	name: 'InvalidImageDialog',
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
};
</script>